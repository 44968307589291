
@import '../../scss/definitions';
.modal-mask {
  position: fixed;
  z-index: 990;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: table;
  transition: opacity 0.3s ease;
}

.dialog-wrapper {
  z-index: 999;
  position: fixed;
  /*   For top and see the data properties. For width only set the min and let the content decide the rest */
  min-width: $dialog-width-threshold;
  max-width: 70rem;
  max-height: 90%;
  display: flex;
  flex-flow: row wrap;
  flex: 1 100%;
  font-size: 1rem;
  font-weight: normal;
  background-color: $dialog-wrapper-background-color;
  border: 1px solid $grey60;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  //margin-left: 1rem;
}

.small.dialog-wrapper {
    max-width: 40rem;
  .dialog-body {
    max-height: 200px;
  }
}
.dialog-body {
  padding: 1rem;
  margin-bottom: 0;
  max-height: calc(80vh - 6rem);
  width: 100%;
  border-bottom: 1px solid #2d2d2d;
  overflow-y: auto;
  overflow-x: hidden;
}
.dialog-footer {
  height: 3rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  overflow-y: auto;
}
.dialog-header{
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  //max-height: 6rem;
  overflow-y: auto;
  max-height: 8rem;
  background-color: $grey20;
  border-bottom: 1px solid $grey60;
  padding: 0.5rem 1rem 0.5rem 1rem;
  //margin-bottom: 1rem;
  touch-action: none;
}
.draggable {
  cursor: move;
}
.fullScreen { // over rides .dialog-wrapper
  font-size: 1.25rem;
  max-width: 98%;
  max-height: 96%;
  .dialog-body {
    max-height: calc(86vh - 6rem);
  }
}
.compactLayout .fullScreen {
  font-size: 1rem;
}

::v-deep(.dialog-header h2),
::v-deep(.dialog-header h3){
  margin: 0;
}

.dialog-header-title {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: $main-width-threshold1){
  .dialog-header-title {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: $dialog-width-threshold){
  .dialog-wrapper{
    min-width: inherit;
    max-width: 100%; /* fit the contents to the screen */
  }
  .dialog-content {
    min-width: inherit;
    max-width: inherit;
  }
  .dialog-header {
    //max-height: inherit; /* let the patient banner height fit the content */
    overflow: auto;
  }
}

.dragActive {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

.error-color {
  color: red;
}

.dialog-header-buttons {
  display: flex;
  justify-content: flex-end;
}

/* *******
  * The following styles are auto-applied to elements with
  * transition="dialog" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the dialog transition by editing
  * these styles.
  */

.dialog-enter {
  opacity: 0;
}

.dialog-leave-active {
  opacity: 0;
}

.dialog-enter .dialog-container,
.dialog-leave-active .dialog-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.dark-theme .dialog-wrapper,
.dark-theme .dialog-body,
.dark-theme .dialog-footer {
  background-color: $grey30;
}
