
@import '../../../scss/definitions';
.fa {
  height: 1.5em;
  font-size: 1.6rem;
  color: $brand-primary;
  padding-left: 5px;
}
.titleBar {
  display: flex;
  //grid-template-columns: 1fr 1rem;
  line-height: 1.25rem;
  cursor: pointer;
  text-align: right;
}
.dropdownWindow {
  border-radius: 5px;
  border: 1px solid $grey60;
  padding: 1rem;
  position: absolute;
  z-index: 10;
  background-color: $white;
  color: $grey80;
}

/* mobile first */
.dropdownWindow {
  right: 0; /* keeps the drop down on the screen when window is small */
}
@media screen and (min-width: $main-width-threshold2) {
  .dropdownWindow {
    right: 2rem;
  }
}

