
@import '../../scss/definitions';


.systemMessage {
  background-color: $info;
  color: $system-message-text;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.message-title {
  font-weight: bold;
  font-size: 1em;
}
/* If api error exists then change the background color */
.apiError {
  background-color: $error;
  color: $system-message-text;
}

.wrapper {
  padding: 10px 20px;
}

.flash-close {
  color: $white;
  top: 0;
}

