
@import "../../scss/definitions";
.sso-input {
  max-width: 8rem;
}
.signed-in-user {
  margin-right: 1rem;
  margin-top: 0.25rem;
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.student-sign-button {
  @media screen and (max-width: 700px) {
    width:75px;
  }
}

.so-group {
  display: flex;
  flex-direction: column;
}
.so-element {
  label {
    min-width: 5rem;
  }
}
