
.ui-confirm {
}
.confirm-body {
  max-width: 60rem
}
.confirm-body a {
  color: blue !important;
  font-weight: bold;
}


