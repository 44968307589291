

.multi-patient-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  background-color: #ffffff;
  //color: #343a40;
  border-radius: 4px;
  width: 100%;
}
