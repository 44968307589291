
@import "../../scss/definitions";
.draft {
  background-color: $table-draft-colour !important;
  color: black !important;
}
.student-submit {
  display: flex;
  flex-direction: row;
  div {
    margin-right: 5px;
  }
}
.status-message {
  font-size: 1.2rem;
  font-weight: normal;
}
button {
  min-width: 5rem;
}
