
@use "sass:math";
@import "../../../scss/definitions";

// 颜色方案
$secondary-color: #6c757d;
$background-color: #f8f9fa;
$text-color: #343a40;

// 字体设置
$font-family: 'Roboto', sans-serif;
$font-size-base: 16px;
$line-height-base: 1.5;

// 边距和内边距
$margin-base: 1rem;
$padding-base: 1rem;


.eval-space {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: $background-color;
  padding: 1rem 1rem 1rem 8rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: $margin-base; // 增加列间距
  @media screen and (max-width: 800px) {
    padding: $padding-base;
  }
}

.input-container {
  grid-column: 1 / 2; // 输入框占据左侧2/3
}

.right-container {
  grid-column: 2 / 3; // 文字和按钮部分占据右侧1/3
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-left: 1rem;
}

.info-container {
  margin-bottom: math.div($margin-base, 4);
}

h3 {
  font-family: $font-family;
  font-size: math.div($font-size-base * 1.25, 1); // 使用 math.div
  color: $text-color;
  margin-bottom: math.div($margin-base, 2); // 使用 math.div
}

.eval-input {
  margin-top: $margin-base;
  background-color: $background-color;
}

.controls-container {
  display: flex;
  flex-direction: column;
  gap: math.div($margin-base, 2); // 使用 math.div
  margin-top: auto; // 将按钮部分推到下方
}

.menu_space_across {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $margin-base;
}

h4 {
  font-family: $font-family;
  font-size: math.div($font-size-base * 1.125, 1); // 使用 math.div
  color: $text-color;
  margin: 0;
}

.ui-link {
  text-decoration: none;
  color: #051F54;
  display: flex;
  align-items: center;
  cursor: pointer;

  .fa {
    margin-right: math.div($margin-base, 4); // 使用 math.div
  }
}

.confirmDialog {
  .fa {
    margin-right: math.div($margin-base, 4); // 使用 math.div
  }
}
