
@import '../../scss/definitions';
$contentMinHeight: 700px;
main {
  padding-bottom: 5rem;
  position: relative;
}

/* Padding and margins and overflow*/
.ehr_layout {
  margin: 1px;
  .ehr_layout__nav {
    padding: 0;
    margin: 0;
    position: relative;
  }
  .ehr_layout__nav_control {
    position: relative;
    bottom: 0;
  }
  .ehr_layout__content {
    padding: 0;
    margin: 0;
  }
  .pageTitle,
  .patient-banner {
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .patient-banner {
    padding-left: 5px;
  }
}
.ehr-no-content {
  height: 40rem;
  display: flex;
  position: relative;
  align-content: center;
  & div {
    font-size: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10rem;
    //width: 40rem;
  }
}
.ehr-banner-context {
  padding-left: $ehr-layout-padding-left;
  padding-right: $ehr-layout-padding-left;
  gap: 10px;
}
.left_side_banner { // page title
  width: 15%;
}
.right_side_banner { //patient data
  width: 100%; // set width to fill container to get nice wrapping
}

@media screen and (max-width: $main-width-threshold3) {
  .flow_across {
    flex-direction: column;
  }
  .left_side_banner,
  .right_side_banner,
  .left_side_content,
  .right_side_content {
    width: 100%;
  }
}

.ehr-main {
  position: relative;
  display: flex;
  flex-direction: row;
}
.ehr-large-screen {
  width: 100%;

  .content-side {
    flex: 1 1 auto;
    width: 52%;

    .nav-side {
      flex: 0 1;
    }
  }
}

.hamburger-action-enter-active, .hamburger-action-leave-active  {
  transition: opacity .15s;
}
.hamburger-action-enter, .hamburger-action-leave-to {
  opacity: 0;
}

.banner-button {
  width: 1.5rem;
}

/* FONTS TEXT */
.pageTitle {
  font-weight: bold;
  font-size: 1.2rem;
}

/* COLOURS */
.content-side {
  border-bottom: 1px solid $grey40;
}
.ehr-banner-context {
  border-bottom: 1px solid black;
}
.nav-side,
.ehr-banner-context {
  background-color: white;
}
.ehr-context {
  background-color: white;
}
.ehr-branding {
  .ehr-banner-context,
  .ehr-context {
    border-left: 2px solid $colour-brand-ehr;
    border-right: 2px solid $colour-brand-ehr;
  }
}
.lis-branding {
  .ehr-banner-context,
  .ehr-context {
    border-top: 2px solid $colour-brand-lis;
    border-left: 2px solid $colour-brand-lis;
    border-right: 2px solid $colour-brand-lis;
  }
}

.ehr-main-button {
  display: none;
}

