
@import '../../scss/definitions';
.dialog-content div {
  margin-bottom: 5px;
}
.fld-label {
  font-weight: bold;
  display: inline-block;
  min-width: 10rem;
}
