

textarea {
  width: 100%;
  height: 15rem;
}

.dialog-wrapper{
  z-index: 1111 !important;
}
