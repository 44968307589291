
@import '../../scss/definitions';

main {
  min-height: 90vh;
  padding-bottom: 2rem;
}
.left_side {
  //width: 16%;
  min-width: 15rem;
  max-width: 15rem;
}
.right_side {
  width: 100%;
  //margin-left: 1rem;
  overflow-x: auto;
}
.left_side_small {
  width: 3.3rem !important;
  min-width: 3.3rem !important;
}
.right_side_large {
  width: 94% !important;
}
@media screen and (max-width: $main-width-threshold3) {
  .flow_across {
    flex-direction: column;
  }
  .left_side {
    width: auto;
  }
  .right_side {
    width: 100%;
  }
}

.outside-main {
  padding: 1.5rem;
}

.flow_across_last_item {
  display: none;
}

.left_side_texttop {
  padding-left: 40px;
  padding-top: 5px;
}

.collapse-button {
  margin-bottom: 1rem;
}
.collapse-button button {
  background-color: $grey20;
  color: $brand-primary;
  box-shadow: none;
  border-radius: $button-border-radius;
  margin-right: 5px;
  margin-top: 5px;
  width: 1.75rem;
  border-color: rgba(0, 0, 0, 0.2)
}
.collapse-button button:focus {
  background-color: $grey20;
  color: $brand-primary;
  box-shadow: none !important;
}
.collapse-button button:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5) !important;
}

.lmsNavPanel {
  background-color: white;
  transition: .3s width ease-in-out;
}

.smScrnShowNav {
  height: auto !important;
}
.smScrnHideNav {
  height: 0;
  overflow: hidden;
}

