.tableLabelElement {
  background-color: $table-header-colour;
}
.tableValueElementOdd {
  background-color: $table-row-odd;
  pre {
    background-color: $table-row-odd;
  }
}

.draftRow,
.draftRow td {
  background-color: $table-draft-colour !important;
}
.draftTableElem {
  background-color: $table-draft-colour !important;
}

.e-table-container {
  overflow-x: auto;
}

/*
https://www.w3.org/Style/css2-updates/css2/tables.html
The default style sheet for HTML4
table    { display: table }
tr       { display: table-row }
thead    { display: table-header-group }
tbody    { display: table-row-group }
tfoot    { display: table-footer-group }
col      { display: table-column }
colgroup { display: table-column-group }
td, th   { display: table-cell }
caption  { display: table-caption }

 */
.e-table {
  display: table;
  border-collapse: collapse;
  letter-spacing: 1px;
  width: 100%;
  .tbody {
    display: table-row-group
  }

  .thead {
    display: table-header-group
  }

  .row {
    display: table-row;
  }

  .cell {
    display: table-cell;
    padding: 5px;
    border: 1px solid $table-cell-border-colour;
  }

  .caption {
    display: table-caption;
    text-align: right;
  }

  .thcell {
    display: table-cell;
    font-size: 1.1rem;
    background-color: $grey15;
    text-align: center;
    font-weight: bold;
    padding: 5px;
    border: 1px solid $table-cell-border-colour;
  }
  .row:nth-child(odd)  { background-color: $grey03; }
  .row:nth-child(even) { background-color: $grey05; }
  .row:hover {
    transition: background-color 0.5s ease;
    background-color: $brand-primary-hover;
  }
}

.seed-theme {
  .thcell {
    background-color: $brand-red60;
  }
}
.lobj-theme {
  .thcell {
    background-color: $brand-blue60;
  }
}


table,
.table {
  border-collapse: collapse;
  //border: 2px solid $grey40;
  letter-spacing: 1px;

  td, th {
    border: 1px solid $table-border-colour;
    padding: 5px;
  }
  th {
    background-color: $table-header-colour;
  }

  th,
  .content table thead th,
  .column_label {
    color: $grey60;
    font-size: 1em;
  }

  &.table_horizontal {
    width: 100%;
    tr:nth-child(odd) td {
      background-color: $table-row-odd;
      pre {
        background-color: $table-row-odd;
      }
    }

    tr:nth-child(even) td {
      background-color: $table-row-even;
      pre {
        background-color: $table-row-even;
      }
    }
  }
  .table_vertical {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    overflow-x: auto;
    width: 100%;
    td:nth-child(odd) td {
      background-color: $table-row-odd
    }

    td:nth-child(even) td {
      background-color: $table-row-even;
    }
  }
  .column_label {
    max-width: 10rem;
  }
}

.accordion table {
  margin-bottom: 0;
}

.column_table {
  .column_label {
    text-align: right;
  }
}

.row_table {
    margin-bottom: 8em;
}

.no-data {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 3em;
}

.table-cell-textarea {
  min-width: 20rem;
}

/*
inputs of type lab_result have suffix data containing the reference ranges. These are provided as html to account for more than one line.
Be sure to remove the usual paragraoh bottom margin to keep the tables compact.
 */
td.tableLabelElement div p {
  margin: 0;
}
