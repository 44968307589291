
.modal-mask {
  position: absolute;
  z-index: 4; /* trial and error to have the mask cover EHR tables with sticky headers and yet allow the sign in dialog to come out on top of this mask*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  //vertical-align: middle;
}

.modal-container {
  position: relative;
  top: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 20rem;
  height: 15rem;
  padding: 20px 10px 0 10px;
  background-color: darkgray;
  font-size: 1.25rem;
  border-radius: 5px; /* 添加圆角 */
}

//.fade-enter-active {
//  transition: opacity .15s;
//}
.fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.sign-button {
  margin-bottom: 1rem;
}
