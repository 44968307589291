
@import '@/scss/definitions';
.file-label {
  margin-bottom: 1px !important;
}
.version-label, .tags-label, .name-label {
  width: 7rem;
}
.version-input {
  width: 10rem;
}

.app-type-section {
  label {
    position: relative;
    top: 5px; // to align with the toggle button
  }
}
