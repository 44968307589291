@import '../definitions';

footer {
  background: $toolbar-background-color;
  color: $toolbar-color;
  padding: 20px;
  p {
    color: $white;
    margin-bottom: 0;
  }
  a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: underline;
    &:hover {
      color: rgba(255, 255, 255, 1);
      transition: 350ms ease-out;
    }
  }
}

.wrap-items-spaced {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.wrap-items-left {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.wrap-items-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.wrap-items-space-between {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.item-12 {
  width: 12%;
  padding-left: 5px;
}
.item-25 {
  width: 25%;
  padding-left: 5px;
}
.item-50 {
  width: 50%;
  padding-left: 5px;
}
.item-center {
  margin: auto;
}

/* Small screens */
@media all and (max-width: $main-width-threshold1) {
  .wrap-items-space-between {
    flex-flow: row;
  }
  .item-12,
  .item-25,
  .item-50 {
    width: inherit;
  }
  .item-center {
    margin: inherit;
  }
}