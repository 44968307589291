@import "../definitions";

body, button, input, select, textarea {
  color: $black;
  font-family: $bodyFontFamily;
  font-size: $bodyFontSize;
  line-height: 1.4;
}

.card-footer {
	color: $black;
	font-family: $bodyFontFamily;
	font-size: $bodyFontSize;
	line-height: 1.4;
}
h1, h2, h3, .h3, h4, h5, h6 {
	color: $grey90;
	font-family: $bodyFontFamily;
	font-weight: bold;
	line-height: 1.2;
}

h1 {
	font-size: 30px;
	margin-bottom: .75em;
}

h2 {
	font-size: 24px;
	margin-bottom: .625em;
	margin-top: .625em;
}

h3, .h3 {
	font-size: 20px;
	margin-bottom: .5em;
	margin-top: .5em;
}

h4 {
	margin-bottom: 1em;
}

p {
	color: $black;
	margin-bottom: 1em;
	max-width: 60rem;
}

blockquote {
}

.quote_wrapper {
	margin-left: 2rem;
	margin-bottom: 1rem;
	max-width: 50rem;
	font-size: 18px;

	blockquote {
		font-family: "Times New Roman";
	}
	.quote_source::before {
		content: '—'
	}
}
.outside-layout {
	a {
		font-size: 18px;
		text-decoration: underline;
	}
	p, li, blockquote {
		font-size: 18px;
	}
}
.assetLink {
	margin-bottom: .5em;
}

.icon-right {
	margin-left: 5px;
}

.icon-left {
	margin-right: 5px;
}

.icon-group {
    position: absolute;
    right: 0;

    & svg.svg-inline--fa {
    	font-size: 20px;
	}
}

.errorMessageList, .errorMessage {
    color: $error;
}
