
@import '../../scss/definitions';
.app-header-bottom {
  display: grid;
  grid-template-columns: 1fr 1rem;
  & .app-header-bottom-left {
    border-bottom: 2px solid $brand-highlight;
  }
  & .app-header-bottom-right {
    border-bottom: 2px solid $brand-highlight-red;
  }
}
.app-header-brand {
  min-width: 10rem;
}
.display-name {
  margin-top: 10px;
}
.side-menu {
  margin-top: 10px;
  margin-right: 10px;
}
.brand-image {
  height: 2.5rem;
  margin-top: 6px;
  margin-left: 2rem;
  margin-bottom: 2px;
}
/* Menu colours */

/* Small screens */
@media screen and (max-width: $main-width-threshold3){
  .brand-image {
    height: 2rem;
    margin-top: 6px;
    margin-left: 1rem;
    margin-bottom: 0;
  }
  .side-menu {
    margin-top: 6px;
  }
}
