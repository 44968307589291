@import '../definitions';
button,
.button {
  font-family: $bodyFontFamily;
  transition: all 0.3s;
}

/***** default button style for whole app (e.g. inside EHR) ****/
button,
.button,
.button.is-primary {
  background-color: $brand-primary;
  &:hover {
    background-color: #051F54;
    color: white;
  }
}

/*
Take a little control here to make sure buttons, with focus, don't become passive grey blobs.
To see this effect, be an instructor, go to an activity, select the gear button, select a learning object in the list. The save button should now be focused.
 */
.button.is-primary:focus,
{
  background-color: $brand-primary-hover;
  border-color: $brand-primary;
  color: inherit;
}

/*
The ehr area has a context banner that represents the outer LMS side.
When there is a button there we want it to match those on the outside
 */
.ehr_layout .zone-lms-button {
  font-size: 1rem;
  font-weight: bold !important;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  height: 2.2rem !important;
  min-width: 2.2rem;
  background-color: $grey10 !important;
  color: $brand-primary !important;
  border: 1px solid $grey60 !important;
}

.outside-layout button,
.outside-layout .button{
  font-size: 1rem;
  font-weight: bold;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  height: 2.2rem;
  //min-width: 2.8rem;
  background-color: $grey10;
  color: $brand-primary;
  border: 1px solid $grey60;
}
.outside-layout .paginate-button {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
}
.outside-layout .paginate-button:hover {
  color: white;
  box-shadow: 1px 1px 2px $grey60;
  border: 1px solid $grey30;
  font-weight: bolder;
}

.paginate-button.is-primary:focus,
{
  border-color: inherit;
  color: inherit;
}

/* .is-primary is, by default, included in the following*/
.ehr_layout button,
.ehr_layout .button{
  height: 2rem;
  background-color: $brand-highlight;
  color:  $white;
  font-weight: bolder;
  border: none;
}
.ehr-branding button,
.ehr-branding .button{
  background-color: $colour-brand-ehr;
  color: white;
  &:hover {
    background-color: $colour-brand-ehr-hover;
  }
}
.lis-branding button,
.lis-branding .button{
  background-color: $colour-brand-lis;
  &:hover {
    background-color: $colour-brand-lis-hover;
  }
}

/* .is-primary is, by default, included in the following*/
.app-header button,
.app-header .button{
  height: 1.6rem;
  font-size: 1rem;
  background-color: $brand-highlight;
  color:  $white;
  border: none;
}

/*****  when buttons show labels reduce font size  ******/
// stopped adjusting size. Just keep same size because transistion is visually disruptive
//.showing-labels button,
//.showing-labels .button,
//.dialog-wrapper button,
//.dialog-wrapper .button {
//  font-size: 1rem;
//}


/*****  is-light overrides  ******/
button.is-light,
.button.is-light, {
  background-color: $white;
  border: 1px solid $brand-primary;
  color: $brand-primary;
  & .fa {
    color: $brand-primary;
  }
  &:hover {
    background-color: $white;
    border: 1px solid $brand-primary;
    color: $brand-primary;
    //transition-duration: 0.3s;
    & .fa {
      color: $brand-primary;
    }
  }
}

/*****  is-danger overrides  ******/
button.is-danger,
.button.is-danger {
  background-color: $white;
  border: 1px solid $red;
  color: $red;
  & .fa {
    color: $red;
  }
  &:hover {
    background-color: $white;
    border: 1px solid $brand-primary-hover;
    color: $brand-primary-hover;
    transition-duration: 0.3s;
    & .fa {
      color: $brand-primary-hover;
    }
  }
}

/*****  disabled overrides  ******/
button,
.button,
.button.is-danger,
.button.is-primary,
.button.is-light {
  &:disabled {
    background-color: $grey10;
    border-color: $grey30;
    color: $grey30;
    cursor: not-allowed;
    .fa {
      color: $grey60;
    }
  }
}

