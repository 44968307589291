@import '../definitions';

.outside-view {
  margin: 1rem;
}

@media screen and (max-width: $main-width-threshold3){
  .outside-view {
    margin: 0 1rem;
  }
}

.explain-container.explain-page-intro {
  margin-top: 1rem;
  padding:10px
}
.explain-page-element {
  margin-bottom: 1rem;
}
.explain-button {
  padding: 0 0 0 5px;
  font-weight: normal;
  height: 1.8rem;
}
.explain-container {
  font-style: italic;
  padding: 5px;
  background-color: $grey10;
  border-radius: 0.25rem;
  max-width: 60rem;
  p {
    margin: 0 0 5px 0;
    font-size: inherit;
    text-indent: 20px;
  }
}

.list-card {
  border: 1.5px solid #051F54;
  border-radius: 10px;
}
.list-element {
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
}

.list-item-container {
  display: grid;
  grid-template-columns: 2fr minmax(30rem, 1fr);
}
.list-item-name {
  font-weight: bold;
  font-size: 1.5rem;
  grid-column: 1;
}

@media screen and (max-width: $main-width-threshold2){
  .list-item-container {
    grid-template-columns: 1fr;
  }
}

.dropdownWindow {
  border-radius: 5px;
  border: 1px solid $grey60;
  padding: 0.5rem;
  background-color: $white;
  color: $grey80;
  // make the list align left
  text-align: left;
}

.details-container {
  margin-top: 1rem;
  padding: 1rem;
}
.details-row {
  display: grid;
  padding: 5px;
}
.details-row {
  grid-template-columns: 1fr 3fr;
}
.details-row-dates .details-name,
.details-row-dates .details-value {
  font-size: 14px;
}

.details-name {
  text-align: right;
  margin-right: 8px;
  font-size: 18px;
  &::after{
    content: ':';
  }
}
.details-value {
  font-size: 18px;
}
.details-action-bar {
  margin-bottom: 1rem;
}
.details-important-text {
  font-size: 16px;
  font-weight: 550;
}

// if smaller than threshold ...
@media screen and (max-width: $main-width-threshold4){
  .details-row {
    display: flex;
    flex-direction: column;
  }
  .details-name {
    text-align: left;
    margin: 0;
  }
}

.with-title {
  grid-template-columns: 1fr 2fr 1fr;
}

.router-item {
  display: block;
  color: $brand-primary;
  font-family: $bodyFontFamily;
  text-decoration: none;
  box-shadow: none;
  .fa {
    display: inline-block;
    width: 2rem;
  }
}

.outside-admin-page {
  .selectable {
    cursor: pointer;
  }
  .row {
    padding-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 0;
    width: 90vw;
  }
  .kvpair {
    display: flex;
    flex-flow: row;
    div {
      border: 1px solid #b5b5b5;
      padding: 5px;
    }
  }
  .key {
    width: 20%;
  }
  .value {
    width: 80%;
    //background-color: #b5b5b5;
  }
}
