@import '../definitions';

$labelWidth: 10rem;
.dialog-step {
  margin-bottom: 10px;
  label {
    max-width: 50rem;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s
}
.fade-enter,
.fade-leave-to {
  opacity: 0
}
.dialog-item {
  display: flex;
  margin-bottom: 1rem;
  textarea {
    padding: 10px;
    max-width: 80%;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px #999;
  }
  label:nth-child(1) {
    padding-right: 5px;
    min-width: $labelWidth;
    max-width: $labelWidth;
    width: $labelWidth;
  }
}
