
@import '../../scss/definitions';
.patient-banner {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}
.patient-data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  > div {
    span {
      display: inline;
    }
    span:nth-child(1) {
      padding-right: 5px;
      &::after {
        content: ':';
      }
    }
    span:nth-child(2) {
      padding-left: 5px;
      font-weight: bold;
    }
  }
}

.patient-name {
  font-weight: bold;
  font-size: 1.1rem;
}
