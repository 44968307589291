
@import '../../scss/definitions';

.button-close {
  background: none;
  border: none;
  color: $grey40;
  content: 'faTimes';
  display: inline-block;
  font-size: 1.25em;
  padding: 0;
  position: absolute;
  right: 2rem;
  top: 1rem;
  &:hover {
    color: $black;
  }
}
