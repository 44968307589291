@import '../definitions';

$form-threshold: $main-width-threshold3;
/*
The datepicker plugin has styling that needs adjustments.
1. Need to over ride the border to display one.
2. Need to change the position from absolute to relative so the date picker will appear within a dialog. Otherwise
the date picker may be chopped and much of it might not be visible.
 */

.vdp-datepicker__calendar {
  position: relative;
}
.smaller-than-900 {
  .ehr-nav-menu {
    text-align: left;
    margin-left: 1em;
    .ehr-nav-hamburger {
      font-size: 2rem;
    }
  }
}
.ehrdfe {
  width: 100%;
  margin-left: 1%;
  margin-right: 1%;
}

li {
  list-style-type: none;
}

/* used in EHR defs.  Also appears as the attribute hideTableElement */
.hide-table-element, .hideTableElement {
  display: none;
}

.no-border {
  border: none !important;
}
/* EHR tables have ids. This hides them from view */
.row-id {
  //display: none;
  //border: 1px solid red;
}

label,
input,
textarea {
  display: inline-block;
}
.form_label {
  text-align: right;
  padding-right: 5px;
  &::after {
    content: ":"
  }
}
.form_label_short {
  .form_label {
    width: 4rem;
  }
}
.form_label_long {
  .form_label {
    width: 20rem;
  }
}
.form_label_mandatory::before {
  content: '*';
  color: $brand-highlight-red;
  display: inline-block;
  width: 12px;
  vertical-align: -4px;
}

.bold-text {
  font-weight: bold;
}

.checkbox_label,
.radio_label {
  display: inline;
  vertical-align: top;
  margin-left: 5px;
}

.checkbox,
.radio {
  display: inline;
  margin-right: 5px;
}

.label-checkbox {
  display: inline-block;
}

hr {
  margin: 5px 0; // remove default 1.5rem above and below
}
input,
.input,
textarea {
  border: 1px solid $grey20;
  border-radius: 3px;
  box-shadow: inset 0 1px 0 0 rgba(21, 26, 36, 0.5);
  box-sizing: border-box;
  font-size: 14px;
  padding: 5px;
}

.ui-info-placeholder {
  display: inline-block;
  width: 1.5rem;
}
textarea {
  height: 7rem;
  width: 100%;
}

.header-3 {
  font-weight: bold;
  font-size: 1.15rem;
}
input, textarea, select, input:disabled, textarea:disabled, select:disabled, checkbox:disabled, radio:disabled, .input:disabled, .textarea:disabled, .select:disabled, .select select:disabled {
  color: $black;
  font-weight: bold;
  font-size: $bodyFontSize;
  line-height: 1.4;
}

input:disabled, textarea:disabled, select:disabled, checkbox:disabled, radio:disabled, .input:disabled, .textarea:disabled, .select:disabled, .select select:disabled {
  background: 0;
  border: 1px solid $grey10;
  box-shadow: inset 0 1px 0 0 rgba(21, 26, 36, 0);
}

/*
Fix for radio buttons on chrome which showed a bar across the top when box-shadow is defined
 */
input[type=radio] {
  box-shadow: none;
}

.is-invalid {
  border: 1px solid red;
}

/* gets rid of double border on time inputs */
.input input {
    border: none;
}

.input-element {
  margin-right: 20px;
  //@include debugColor(c)
}

.input-element:last-child {
  margin-right: 0;
}

.input-element-full {
  width: 100%;
}

/* input-element-small is applied to an ehr form field wrapper so we need to shape the inner text input */
.input-element-small {
  input {
    max-width: 10rem;
  }
}

.input-element-medium {
  flex: 2 0 15rem;
}

.embedded-data {
  border: 1px dashed;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 1rem;
}
.embedded-data > input {
  font-size: 0.8rem;
}
.vdp-datepicker * {
  width: 100%
}

.checkbox_wrapper,
.checkset_wrapper,
.computed_wrapper,
.file_wrapper,
.form_label_wrapper,
.radio_wrapper,
.select_wrapper,
.sim_day_wrapper,
.sim_time_wrapper,
.textarea_wrapper,
.care_provider_wrapper,
.text_input_wrapper
{
  position: relative;
  display: flex;
  align-content: space-between
}

$select_width: 14rem;
$select_width_2col: 20rem;

.computed_wrapper {
  .input {
    max-width: $select_width;
  }
}
@media all and (max-width: $form-threshold) {
  .computed_wrapper {
    .input {
      max-width: inherit;
    }
  }
}

.select_wrapper {
  .select {
    max-width: $select_width;
    margin-bottom: 5px;
  }
}
.compactLayout .select_wrapper {
  .select {
    max-width: inherit;
    margin-bottom: 0;
  }
}


.sim_day_wrapper,
.text_input_wrapper {
  .input {
    margin-bottom: 5px;
  }
}
.compactLayout .text_input_wrapper {
  .input {
    margin-bottom: 0;
  }
}

.care_provider_wrapper {
  .form_label {
    min-width: 15rem;
  }
}
.number-short .numb-input,
.number-short .computed_wrapper .input{
  width: 7rem;
}
.textarea_wrapper {
  textarea {
    margin-bottom: 5px;
  }
}

.checkset_wrapper {
  flex-flow: row;
  .checkset_list {
    display: flex;
    // let the check items flow across and wrap to fill the space
    flex-flow: row wrap;
    column-gap: 1rem;
    align-content: flex-start;
    //border: 1px solid $grey20;
    padding: 5px;
    margin-bottom: 5px;
    label {
      min-width: 5rem;
    }
    @media screen and (max-width: $main-width-threshold1) {
      max-height: inherit;
    }

  }
}

.lab_result_wrapper {
  /*
   EHR dialog form element of input type lab_result. These contain a label, small text input for a number and
   a "normal range" suffix.

   The lab_result elements each have their own row in the form.
   */
  display: block;

  input {
    max-width: 8rem;
  }

  .lab_result_element {
    display: flex;
    flex-flow: row;
  }
  .lab_result_value {
    min-width: 10rem !important;
    text-align: right;
    margin-right: 1rem;
  }
  .lab_result_label {
    display: inline-block;
    label {
      min-width: 20rem !important;
      /* Drop the label a little to line up with text in the input. There is a better way to do this.*/
      padding-top: 5px;
    }
  }
  .lab_result_suffix {
    display: inline-block;
    margin-left: 5px;
    /* pad the range to match label if there is just one line. Don't pad if multi-lined*/
    p {
      padding: 0;
      margin: 0;
    }
    p:only-child {
      padding-top: 5px;
    }
  }

}
@media all and (max-width: $form-threshold) {
  .lab_result_wrapper {
    .lab_result_label {
      label {
        min-width: inherit;
      }
    }
  }
}
.checkbox_wrapper {
    margin-bottom: .625em;
}

input[type="checkbox"]{
  margin-right: 5px;
  max-width: 1rem;
  vertical-align: middle;
  box-shadow: none;
}

/* A radio set is a group of radio inputs with labels. */
.radio_set {
  label {
    margin-left: 5px;
  }
}
select, .select {
  background: linear-gradient(180deg, rgba(203, 206, 209, 0.2) 0%, rgba(133, 139, 148, 0.2) 100%);
  height: 2.25em;
  width: 100%;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: $grey40;
}

.select select:disabled::after {
  border-color: $grey10;
}

.full-width {
  width: 100%;
}

.is-half {
  max-width: 50%;
  flex: 1 0 50%;
}

.is-two-thirds {
  max-width: 66%;
  flex: 1 0 66%;
}

.sim_day_wrapper,
.sim_time_wrapper
{
  .input {
    width: 4rem;
  }
}

.ehr-page-form {
  margin-bottom: 8em;
  .ehr-group-for {
    .input {
      max-width: inherit;
      width: 100%;
    }
    .sim_day_wrapper,
    .sim_time_wrapper
    {
      .input {
        width: 4rem;
      }
    }
  }
}

// for dialog and page forms ...
.ehr-group-wrapper {
  .ehr-group-for {
    margin-bottom: 5px;
    margin-right: 5px; // space between elements
    //border: 1px solid blue;
  }
}

.compactLayout .ehr-group-wrapper {
  .ehr-group-for {
    //border: 1px solid $grey05;
    padding: 4px;
  }
}

.column_table {
  .column_label {
    min-width: 15em;
    text-align: right;
  }

  .column_value {
    color: $black;
    min-width: 8rem;
    max-width: 12rem;
  }
}

.datepicker-header {
  text-align: center;
}

.record-header {
  //border-bottom: 1px solid $grey20;
  //margin-bottom: 2.5em;
padding-bottom: 1.5em;
  //padding: 0;
  margin: 0;
  display: flex;
}
@media all and (max-width: $form-threshold) {
  .record-header {
    flex-flow: wrap;
  }
}
.grid-new-subsection {
  margin-top: 10px;
}
.cardio-assessment,
.ehr-banner-grid,
.grid-left-to-right-custom,
.grid-left-to-right-1,
.grid-left-to-right-2,
.grid-left-to-right-3,
.grid-left-to-right-4,
.grid-left-to-right-5 {
  display: grid;
  grid-auto-flow: row;
  margin-bottom: 1rem;
}

.grid-left-to-right-1 {
  grid-template-columns: 1fr;
  .form_label_wrapper {
    //width: 20rem;
    .form_label {
      //min-width: 20rem;
      max-width: 10rem;
    }
  }
}

.grid-left-to-right-2 {
  grid-template-columns: repeat(2, 1fr);
  .form_label_wrapper {
    //width: 20rem;
    //.form_label {
    //  min-width: inherit;
    //  max-width: inherit;
    //}
  }
}

.grid-left-to-right-3 {
  grid-template-columns: repeat(3, 1fr);
}

.ehr-page-form {
  .grid-left-to-right-1,
  .grid-left-to-right-2,
  .grid-left-to-right-3 {
    .form_label {
      min-width: 10rem;
      max-width: 10rem;
    }
  }
}

.ehr-dialog-form {
  .grid-left-to-right-3 {
    .form_label {
      min-width: 12rem;
      max-width: 12rem;
      @media screen and (max-width: $main-width-threshold1) {
        min-width: 8rem;
        max-width: 12rem;
      }
    }
    .checkset_wrapper {
      .form_label {
        text-align: left;
        min-width: 5rem;
        max-width: 5rem;
      }
    }

  }
}

.grid-left-to-right-4 {
  grid-template-columns: repeat(4, 1fr);
}

.cardio-assessment,
.grid-left-to-right-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-up-to-down {
  display: grid;
  grid-gap: 1rem 1.25rem;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
}

.grid-span-2-columns {
  grid-column: span 2;
}
.grid-span-3-columns {
  grid-column: span 3;
}
.grid-span-4-columns {
  grid-column: span 4;
}


.grid-span-2-columns,
.grid-span-3-columns,
.grid-span-4-columns
{
  .computed_wrapper {
    .input {
      max-width: inherit;
    }
  }
  .checkbox_wrapper {
    .form_label {
      max-width: inherit;
    }
  }
}

/* Small screens */
@media all and (max-width: $form-threshold) {
  .grid-left-to-right-custom,
  .grid-left-to-right-2,
  .grid-left-to-right-3,
  .grid-left-to-right-4,
  .cardio-assessment,
  .grid-left-to-right-5,
  .grid-up-to-down {
    grid-template-columns: 1fr;
  }

  .grid-span-2-columns,
  .grid-span-3-columns,
  .grid-span-4-columns
  {
    grid-column: span 1;
  }
}

.thumbnail {
  display: inline-block;
  vertical-align: middle;
  max-height: 100px;
  max-width: 100px;
}

.outside-layout .search-box {
  input {
    border: 1px solid blue;
    height: 2.1rem;
    width: 9rem;
  }
  .search-button {
    padding: 5px;
  }
  //.fa {
  //  position: relative;
  //  margin-left: 5px;
  //  top: 4px;
  //}
}

.field_full_line {
  grid-column-start: 1;
  grid-column-end: 4;
}

.rcm-morphology-group {
  display: grid;
  grid-auto-flow: row;
  margin-bottom: 1rem;
  // last column is empty to push content to left
  grid-template-columns: 0.25fr 1fr 0.7fr 3fr;

  // reduce the size of the checkbox labels to make the rcm morphology area more compact
  .check-label label {
    min-width: 14rem;
    max-width: 8rem;
  }
  .form_label {
    min-width: inherit;
    max-width: inherit;
  }
  .select_wrapper .select {
    width: inherit;
    height: inherit;
  }
}
@media all and (max-width: $form-threshold) {
  .rcm-morphology-group {
    grid-template-columns: repeat(1, 1fr);
  }
}

#rcmMcvMchc {
  label {
    min-width: 14rem;
  }
  input {
    min-width: 30rem;
  }
}
// the following select the grouping elements that have both the classes
// note the group element and the internal wrapper are given the same formCss
// here we are laying out the groups
.ehr-group-for.rcm-one-part {
  grid-column-start: span 3;
}
.ehr-group-for.rcm-one-part-indented {
  grid-column-start: 2;
  grid-column-end: 4;
}
.ehr-group-for.rcm-two-part-1 {
  grid-column-start: 2;
}
.ehr-group-for.rcm-two-part-2 {
  grid-column-start: 3;
  grid-column-end: 4;
}

.form_label {
  min-width: 8rem;
  max-width: 8rem;
}
.check-label label {
  min-width: 18rem !important;
  max-width: 18rem;
}
@media all and (max-width: $form-threshold) {
  .form_label {
    min-width: inherit;
    //max-width: inherit;
  }
  .check-label label {
    min-width: inherit;
    max-width: inherit;
  }
}
.text-area-2-rows textarea {
  height: 3rem;
}

.grid-left-to-right-2 select {
  min-width: $select_width_2col;
}

.form-table-group {
  display: grid;
  gap: 1px;
  margin-bottom: 30px;
  .ehr-group-for {
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 0;
    min-height: 3rem;
  }
  .form-element {
    //padding: 0;
    //border: 1px $grey20 solid;
    .form_label_wrapper {
      //padding-left: 10px;
    }
    .text-input {
      height: 2.1rem; // slightly smaller then the form-element so there is a bottom border
      //border: none;
    }
  }
  .formTableHeader {
    font-weight: bold;
  }
  .formTableHeader, .formTableText {
    padding-left: 5px;
    padding-top: 5px;
  }
  .form-table-center-content {
    justify-content: center;
    .form_label_wrapper {
      text-align: center;
      display: block;
    }
  }
  .form-table-span-7 {
    grid-column: span 7;
  }
  .form-table-span-6 {
    grid-column: span 6;
  }
  .form-table-span-5 {
    grid-column: span 5;
  }
  .form-table-span-4 {
    grid-column: span 4;
  }
  .form-table-span-3 {
    grid-column: span 3;
  }
  .form-table-span-2 {
    grid-column: span 2;
  }
  .form-table-span-2-rows {
    grid-row: span 2;
  }
  .form-table-span-3-rows {
    grid-row: span 4;
  }
  .form-table-span-4-rows {
    grid-row: span 4;
  }
}

#ehrDialogForm .form-table-group {
  gap: 0;
  margin-bottom: 0;

  .ehr-group-for {
    border: 1px solid $grey20;
    padding: 1px;
    margin: 0;
    min-height: inherit;
  }
}

/* Hematology */
.wbc-differential-group {
  grid-template-columns: 1fr 1fr 1fr;
}
/*
 Table specific styling
 */

.wbcMorphologyGroup {
  // this group also has the class grid-left-to-right-custom
  grid-template-columns: repeat(3, 1fr);
}
.wbcmNormalMorphology {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}
.wbcmAbnormalMorphology {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
}
.wbcmAbnormalTypeMorphology {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}

.wbcmToxicTypeMorphology {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

@media all and (max-width: $form-threshold) {
  .wbcMorphologyGroup {
    grid-template-columns: 1fr;
  }
  .wbcmNormalMorphology,
  .wbcmAbnormalMorphology,
  .wbcmAbnormalTypeMorphology,
  .wbcmToxicTypeMorphology {
    grid-column-start: initial;
    grid-column-end: initial;
    grid-row-start: initial;
    grid-row-end: initial;
  }
}

.body-main-table {
  grid-template-columns: 0.5fr 0.5fr 0.5fr;
  .text-input {
    width: 16rem;
    max-width: 16rem;
  }
}
.body-diff-table {
  grid-template-columns: repeat(5, 1fr);
  .text-input {
    width: 11rem;
    max-width: 11rem;
  }
}
.med-lab-urin-micro {
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
  .select {
    width: 16rem;
    max-width: 16rem;
  }
}

.med-lab-urin-qc-table {
  grid-template-columns: repeat(7, 1fr);
}

.med-lab-urin-qc-pending-table {
  grid-template-columns: repeat(2, 1fr);
}
.uqcSomeQcUnacceptable {
  .form_label_wrapper {
    width: 25rem;
  }
  .radio_set {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
  }
}
#uqcManOrClinitek {
  margin-bottom: 1rem;
  .radio_set {
    flex-direction: row;
    gap: 1rem;
  }
}
#uqcPerformedBy {
  .form_label {
    min-width: 12rem;
  }
}

.urin-macro-exam {
  grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
  .input {
    width: 10rem;
  }
}

#chemSodiumFlag {
  width: 14rem;
}
.chem-results-table {
  grid-template-columns: 1fr 1fr 0.5fr 1fr;
  .input {
    width: 10rem;
  }
  .chem-results-lhclch .radio_set  {
    display: flex;
    flex-direction: row;
    gap: 10px;
    label{
      margin-left: 2px;
    }
  }
}
.chem-qc-assay {
  grid-template-columns: 2fr 1fr;
  .chem-qc-assay-into {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .radio_set {
    display: flex;
    flex-flow: row;
    gap: 1rem;
    padding-left: 10px;
  }
}
.chem-qc-trouble {
  grid-template-columns: repeat(5, 1fr);
}

#medicationOrders\.med_medication {
  width: 60rem;
}
