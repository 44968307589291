
@import '../../scss/definitions';
.appLmsDashContainer {
  display: flex;
  flex-direction: row;
  .nav-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .leftNav {
    //max-width: 10rem;
    min-width: 10rem;
    margin-right: 1rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
  }
  .rightMain {
  }
}

@media screen and (max-width: $main-width-threshold3) {
  .appLmsDashContainer {
    flex-direction: column;
  }
  .leftNav,
  .rightMain {
    width: 100%;
  }
}

main {
  min-height: 90vh;
  padding-bottom: 2rem;
}
.appLms-layout {
  background-color: $brand-highlight-light;
}
.appLms-page-footer {
  max-width: 90%;
  border: 1px solid darkcyan;
  padding: 1rem;
}
.showDevToggle {
  margin: 1rem;
  padding: 2px;
  width: 1.4rem;
}
