
@import '../../scss/definitions';

.intro-container {
  margin-bottom: 1rem;
}
#searchButton {
  min-width: 14rem;
}
.search-inputs {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}
.search-input-container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  margin-bottom: 1rem;

  & label {
    min-width: 7rem;
    text-align: right;
  }
}
