
@import '../../scss/_definitions.scss';
.activationControl {
  //display: inline-block;
  //position: relative;
  //margin-left: 0.5rem;
}
.fa {
  height: 1.25em;
  color: $brand-primary;
}
