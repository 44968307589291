
@import "../../scss/definitions";
.sdt-display {
  font-weight: 600;
  padding: 5px 5px 5px 1px;
}
.sdt-date {
  max-width: 2rem;
}
.sdt-time {
  max-width: 4rem;
}
.button {
  padding: 5px 5px 5px 7px;
  background-color: transparent !important;  // 设置背景颜色为透明 
  color: #051F54 !important;                // 设置文字颜色为 #051F54

}
