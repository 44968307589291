
@import '../../scss/definitions';
.ehr-context-case-study {
  display: grid;
  grid-template-columns: 1fr 2.5fr 0fr;
  gap: 1rem;
  max-height: 3rem;
  overflow-y: auto;

  .seed-name {
    //color: $black;
    font-weight: bold;
    //padding-top: 10px;
  }
  .seed-description {
    margin-top: 8px;
  }
}
.downloadseedshow {
  @media screen and (max-width: 1200px) {
    display:none;
  }
}
/*.flow_across_front_item {
    min-width: 45%;
  }*/

