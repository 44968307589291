
@import '../../scss/definitions';

.ehr-nav-collapsed {
  width: 9rem;
}
@media (min-width: 768px) {
  .ehr-nav-full {
    width: 20rem;
  }
}

.EhrNavList {
  padding: 0;
  &__teaserList {
    margin-left: 0;
  }
  &__link {
    color: $white;
  }
}
