
@import '../../scss/definitions';

$secondary-color: #6c757d;
$success-color: #28a745;
$warning-color: #ffc107;
$danger-color: #dc3545;
$light-color: #f8f9fa;
$dark-color: #343a40;
$text-light-color: #ffffff; // 新增：浅色文字颜色
$text-dark-color: #343a40; // 新增：深色文字颜色

$indicator-size: 12px;
$indicator-spacing: 15px; // 新增：指示器与文字的间距

.linkElement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  border-radius: 4px;
  //background-color: $dark-color; // 背景颜色改为深色
  //color: $text-light-color; // 文字颜色改为浅色
  width: 100%; // 确保 linkElement 占满整个父容器

}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto; // 确保指示器始终位于右侧
  margin-right: 10px;
}

.circle {
  border-radius: 50%;
  height: $indicator-size;
  width: $indicator-size;
  border: 1px solid $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &.green-circle {
    background-color: $success-color;
    border-color: $success-color;
  }

  &.draft-circle {
    background-color: $warning-color;
    border-color: $warning-color;
  }

  &.empty-circle {
    background-color: transparent;
  }
}

.top-level {
  margin-left: 5px;
  color: $text-light-color; // 文字颜色改为浅色
  transition: transform 0.3s ease;

  &.opened {
    transform: rotate(90deg);
  }
}

.linkLabel {
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 500;
  color: $text-light-color; // 文字颜色改为浅色
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: $indicator-spacing; // 确保文字与指示器之间有足够的间距
}

.tooltiptext {
  visibility: hidden;
  background-color: $dark-color;
  color: $text-light-color; // 工具提示文字颜色改为浅色
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  bottom: 120%; /* Position above the element */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $dark-color transparent transparent transparent;
  }
}

.linkElement:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Small screens */
@media screen and (max-width: $main-width-threshold1) {
  .linkElement {
    padding: 8px 12px;
  }

  .tooltiptext {
    left: 110%;
    bottom: 50%;
    transform: translateY(50%);
  }

  .tooltiptext::after {
    top: 50%;
    left: -5px;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent $dark-color transparent transparent;
  }
}

.EhrNavListItem {
  &__level1,
  &__level2,
  &__level3 {
    display: flex;
    align-items: center;
  }

  &__level1 {
    font-size: 1.2rem;
  }

  &__level2 {
    padding-left: 20px;
  }

  &__level3 {
    padding-left: 40px; // 增加 level3 的左内边距
  }
  &:hover {
    background-color: darken($dark-color, 5%); // 悬停时背景颜色加深
  }
}

.ehr-branding {
  .router-link-active {
    background-color: darken($dark-color, 5%); 
  }
}

