
@import '../../../scss/definitions';
.dropdownItem {
  padding: 5px;
  display: grid;
  grid-template-columns: 2rem 1fr;
  line-height: 1.25rem;
  cursor: pointer;
  //width: 100%;
}

