
@import '../../scss/definitions';

footer {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  background-color: #051F54;
  font-family: Arial, sans-serif;
  color: white;

  .footer-section {
    margin-bottom: 1rem;
    font-size: 14px;
    line-height: 1.6;

    &.about-list {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }


}
